<template>
    <layout-full-width :title="$tc('overview', 1)">

        <div class="row">
            <div class="col-4">
                <div class="card mb-4">
                    <div class="card-header">
                        <strong>{{ $tc('recommendation', 2) }}</strong>
                    </div>
                    <div class="card-body">
                        <p class="py-5 text-center">{{ $t('no-recommendations') }}</p>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card mb-4">
                    <div class="card-header">
                        <strong>{{ $tc('update', 2) }}</strong>
                    </div>
                    <div class="card-body">
                        <p class="py-5 text-center">{{ $t('no-updates') }}</p>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card mb-4">
                    <div class="card-header">
                        <strong>{{ $tc('changelog', 1) }}</strong>
                    </div>
                    <div class="card-body">
                        <p class="py-5 text-center">{{ $t('no-changelog') }}</p>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card mb-4">
                    <div class="card-header">
                        <strong>{{ $t('news') }}</strong>
                    </div>
                    <div class="card-body">
                        <p class="py-5 text-center">{{ $t('no-news') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";

export default {
    name: "PageOverview",
    components: {LayoutFullWidth}
}
</script>

<style lang="scss" scoped>
</style>